//original list
// const M25_POSTCODES = new Set([
//     "EC1", "EC2", "EC3", "EC4",
//     "WC1", "WC2",
//     "N1", "N2", "N3", "N4", "N5", "N6", "N7", "N8", "N9", "N10", "N11", "N12", "N13", "N14", "N15", "N16", "N17", "N18", "N19", "N20", "N21", "N22",
//     "NW1", "NW2", "NW3", "NW4", "NW5", "NW6", "NW7", "NW8", "NW9", "NW10", "NW11",
//     "E1", "E2", "E3", "E4", "E5", "E6", "E7", "E8", "E9", "E10", "E11", "E12", "E13", "E14", "E15", "E16", "E17", "E18",
//     "W1", "W2", "W3", "W4", "W5", "W6", "W7", "W8", "W9", "W10", "W11", "W12", "W13", "W14",
//     "SE1", "SE2", "SE3", "SE4", "SE5", "SE6", "SE7", "SE8", "SE9", "SE10", "SE11", "SE12", "SE13", "SE14", "SE15", "SE16", "SE17", "SE18", "SE19", "SE20", "SE21", "SE22", "SE23", "SE24", "SE25", "SE26", "SE27", "SE28",
//     "SW1", "SW2", "SW3", "SW4", "SW5", "SW6", "SW7", "SW8", "SW9", "SW10", "SW11", "SW12", "SW13", "SW14", "SW15", "SW16", "SW17", "SW18", "SW19", "SW20",


//     "AL2",
//     "BR1", "BR2", "BR3", "BR4", "BR5", "BR6", "BR7", "BR8",
//     "CM14", "CM16",
//     "CR0", "CR2", "CR3", "CR4", "CR5", "CR6", "CR7", "CR8",
//     "DA1", "DA2", "DA4", "DA5", "DA6", "DA7", "DA8", "DA14", "DA15", "DA16", "DA17", "DA18",
//     "EN1", "EN2", "EN3", "EN4", "EN5", "EN6", "EN7", "EN8", "EN9",
//     "HA0", "HA1", "HA2", "HA3", "HA4", "HA5", "HA6", "HA7", "HA8", "HA9",
//     "IG5", "IG6", "IG7", "IG8", "IG9", "IG10", "IG11",
//     "KT1", "KT2", "KT3", "KT4", "KT5", "KT6", "KT7", "KT8", "KT9", "KT10", "KT11", "KT12", "KT13", "KT17", "KT18", "KT19", "KT20", "KT21", "KT22",
//     "RM1", "RM2", "RM3", "RM4", "RM5", "RM6", "RM7", "RM8", "RM9", "RM10", "RM11", "RM12", "RM13", "RM14", "RM15",
//     "SL0",
//     "SM1", "SM2", "SM3", "SM4", "SM5", "SM6", "SM7",
//     "TN14", "TN15", "TN16",
//     "TW1", "TW2", "TW3", "TW4", "TW5", "TW6", "TW7", "TW8", "TW9", "TW10", "TW11", "TW12", "TW13", "TW14", "TW15", "TW16", "TW17", "TW19",
//     "UB1", "UB2", "UB3", "UB4", "UB5", "UB6", "UB7", "UB8", "UB10", "UB11",
//     "WD1", "WD2", "WD3", "WD4", "WD5", "WD6", "WD7"
// ])

//filtered list based on Fernando's criterion
const M25_POSTCODES = new Set([
    "EC1","EC2","EC3","EC4","WC1","WC2",
    "N1","N2","N3","N4","N5","N6","N7","N8","N9","N10","N11","N12","N13","N14","N15","N16","N17","N18","N19","N20","N21","N22",
    "NW1","NW2","NW3","NW4","NW5","NW6","NW7","NW8","NW9","NW10","NW11",
    "E1","E2","E3","E4","E5","E6","E7","E8","E9","E10","E11","E12","E13","E14","E15","E16","E17","E18",
    "W1","W2","W3","W4","W5","W6","W7","W8","W9","W10","W11","W12","W13","W14",
    "SE1","SE2","SE3","SE4","SE5","SE6","SE7","SE8","SE9","SE10","SE11","SE12","SE13","SE14",
    "SE15","SE16","SE17","SE18","SE19","SE20","SE21","SE22","SE23","SE24","SE25","SE26","SE27","SE28",
    "SW1","SW2","SW3","SW4","SW5","SW6","SW7","SW8","SW9","SW10","SW11","SW12","SW13","SW14","SW15","SW16","SW17","SW18","SW19","SW20",
    "BR1","BR2","BR3","BR4","BR5","BR7","CR0","CR2","EN1","EN2","EN3","HA0","HA1","HA2","HA3","HA4","HA5","IG5","IG6","IG7","IG8","IG9",
    "IG10","IG11","KT1","KT2","KT3","KT4","KT5","KT6","KT7","KT8","RM1","RM2","RM3","RM4","RM5","RM6","RM7","RM8","RM9","RM10","RM11",
    "RM12","RM13","TW1","TW2"
])

export const getUKPostcodeInfo = (postcode) => {
    return new Promise((resolve, reject) => {
        fetch(`//api.postcodes.io/postcodes/${postcode}`, { method: "GET" })
            .then(resp => {
                if (resp.ok) {
                    return resp.json().then(data => resolve(data.result))
                } else {
                    const [out,] = postcode.split(" ")
                    return fetch(`//api.postcodes.io/outcodes/${out}`, { method: "GET" })
                        .then(resp => {
                            if (resp.ok) {
                                return resp.json().then(data => resolve(data.result))
                            } else {
                                return reject("Failed fetching postcode data")
                            }
                        })
                }
            })
    })
}



export const withinM25 = (postcode) => {
    const [prefix, _] = postcode.split(/\s+/)
    return M25_POSTCODES.has(prefix)
}