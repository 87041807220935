import React, { useState } from "react"
import styles from "./style.module.css"
import classnames from "classnames"
import { observer, inject } from "mobx-react"

const StartForm = ({ ui }) => {
    const [note, setNote] = useState("")
    return (
        <div>
            <h2>Is there anything you think we should know?</h2>
            <h3>Let us know if you have any item that requires special care</h3>
            <textarea rows="5" value={note} onChange={o => setNote(o.target.value)} />
            <div className="d-flex justify-content-center">
                <button className="bg-primary text-white text-fatty" onClick={() => ui.next("service", { note: note })} > Next</button>
                <button disabled={note} onClick={() => ui.next("service", { note: undefined })} >Nothing to declare</button>
            </div>
        </div>
    )
}

const PackingPage = inject(stores => ({ ui: stores.quote_store }))(
    observer(({ ui }) => (
        <div className={styles.wrapper}>
            <StartForm ui={ui} />
        </div>
    ))
)

export default PackingPage