import React from "react"
import querystring from "querystring"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Chat from "../components/Chat"
import InstantQuote from "../components/InstantQuote"
import QuoteStore from "../stores/quote.store"
import { Provider, inject, observer } from "mobx-react"
import ChatStore from "../stores/chat.store"
import { navigateTo } from "gatsby"
import { reaction } from "mobx"
import QuoteStore2 from "../stores/quote-2.store"
import FormQuote from "../components/FormQuote"

const QuotePageLoader = inject(stores => ({
    context: stores.context_store
}))(
    observer((props) => (
        props.context.version === "2" ?
            <QuotePage {...props} /> : <Quote2Page {...props} />

    ))
)

class Quote2Page extends React.Component {
    constructor(props) {
        super(props)
        this.stores = {
            quote: new QuoteStore2(),
            chat: new ChatStore()
        }
    }

    render() {
        return (
            <Provider
                quote_store={this.stores.quote}
                chat_store={this.stores.chat}
            >
                <Layout scroll={false} headerMode="no-quote">
                    <SEO title="Instant Quote" />
                    <Chat hideOnStart />
                    <FormQuote />
                </Layout>
            </Provider>
        )
    }
}

class QuotePage extends React.Component {

    constructor(props) {
        super(props)
        const quoteId = querystring.parse(props.location.search.replace("?", "")).id
        this.stores = {
            quote: quoteId ? QuoteStore.staticQuote(quoteId) : new QuoteStore(),
            chat: new ChatStore()
        }
        this._disposers = [].concat(
            reaction(() => this.stores.quote.currentPage === "quote", (quoteIsReady) => {
                if (quoteIsReady) {
                    navigateTo(this.stores.quote.url)
                } else {
                    navigateTo("/quote")
                }
            })
        )
    }

    componentWillUnmount() {
        this._disposers.forEach(d => d())
    }

    render() {
        return (
            <Provider
                quote_store={this.stores.quote}
                chat_store={this.stores.chat}
            >
                <Layout scroll={false} headerMode="no-quote">
                    <SEO title="Instant Quote" />
                    <Chat hideOnStart />
                    <InstantQuote />
                </Layout>
            </Provider>
        )
    }
}

export default QuotePageLoader
