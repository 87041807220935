import { api } from "../utils/url"


//return metres, seconds
export const calculateTrip = (sections) => {
    return Promise.all(sections.map(trip => {
        return fetch(api(`/distance/trip`), {
            method: "POST",
            body: JSON.stringify({
                from: trip[0],
                to: trip[1],
            }),
            headers: {
                "Content-Type": "application/json",
                "accepts": "application/json"
            }
        })
            .then(resp => resp.json())
            .then(data => {
                if (data.success) return data.result
                else throw new Error(data.message)
            })
    }))
        .then(values => ({
            distance: values.map(v => v.distance).reduce((res, x) => res += x, 0),
            duration: values.map(v => v.duration).reduce((res, x) => res += x, 0),
        }))
}

