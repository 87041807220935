import React, { useState } from "react"
import styles from "./style.module.css"
import classnames from "classnames"
import { observer, inject } from "mobx-react"

const Form = ({ ui }) => {
    const [property, _setProperty] = useState(null)
    const setProperty = (p) => {
        _setProperty(p)
        setTimeout(() => {
            ui.next("mobility", {
                property: p
            })
        }, 200)
    }
    return (
        <>
            <h2>What kind of property are you currrently in?</h2>
            <div className="d-flex flex-wrap justify-content-center">
                {["Office", "Storage", "Studio", "1 Bedroom", "2 Bedrooms", "3+ Bedrooms"].map(p => (
                    <button key={p} className={classnames({ [styles.active]: property == p })} onClick={() => setProperty(p)} >{p}</button>
                ))}
            </div>
        </>
    )
}

const PropertyPage = inject(stores => ({ ui: stores.quote_store }))(
    observer(({ ui }) => (
        <div className={styles.wrapper}>
            <Form ui={ui} />
        </div>
    ))
)

export default PropertyPage