import React, { useState } from "react"
import styles from "./style.module.css"
import classnames from "classnames"
import { observer, inject } from "mobx-react"
import ReactDropdown from "react-dropdown"

const WhatFloor = ({ visible, value, onChange }) => (
    <div className={classnames(styles.floor, "text-center", { "d-none": !visible })}>
        <h3>What floor is that?</h3>
        <ReactDropdown
            className={styles.dropdown}
            id="current-floor"
            options={[].concat(Array(5).fill().map((_, i) => (
                { value: `${i + 1}`, label: `${i + 1}` }
            )), "5+")}
            value={value}
            onChange={o => onChange(o.value)}
        />
    </div>
)

const Form = ({ ui }) => {
    const [access, setAccess] = useState("")
    const [nextAccess, setNextAccess] = useState("")
    const [nextVisible, setNextVisible] = useState(false)
    const [floorFrom, setFloorFrom] = useState("")
    const [floorTo, setFloorTo] = useState("")
    const [floorsVisible, setFloorsVisible] = useState([false, false])

    const nextPage = (data) => {
        ui.next("packages", data)
    }

    const current = (x) => {
        setAccess(x)
        if (x === "stairs") {
            setTimeout(() => {
                setFloorsVisible([true, floorsVisible[1]])
            }, 100)
        } else {
            setFloorFrom("")
            setFloorsVisible([false, floorsVisible[1]])
            setTimeout(() => {
                setNextVisible(true)
            }, 100)
        }
    }
    const next = (x) => {
        setNextAccess(x)
        if (x === "stairs") {
            setTimeout(() => {
                setFloorsVisible([floorsVisible[0], true])
            }, 100)
        } else {
            setFloorTo("")
            setTimeout(() => {
                nextPage({
                    old_access: { type: access, info: access === "stairs" ? ({ floor: floorFrom }) : ({}) },
                    new_access: { type: x, info: {} }
                })
            }, 200)
        }
    }
    return (
        <>
            <h2>How can we access your current place?</h2>
            <div className="d-flex flex-wrap justify-content-center">
                <button className={classnames({ [styles.active]: access === "ground" })} onClick={() => current("ground")} >From ground-floor</button>
                <button className={classnames({ [styles.active]: access === "stairs" })} onClick={() => current("stairs")} >Using stairs</button>
                <button className={classnames({ [styles.active]: access === "elevator" })} onClick={() => current("elevator")} >Using elevator</button>
            </div>
            <WhatFloor visible={floorsVisible[0]} value={floorFrom} onChange={o => {
                setFloorFrom(o)
                setTimeout(() => {
                    setNextVisible(true)
                }, 100)
            }} />
            <div className={classnames({ "invisible": !nextVisible })}>
                <h2>And your new one?</h2>
                <div className="d-flex flex-wrap justify-content-center">
                    <button className={classnames({ [styles.active]: nextAccess === "ground" })} onClick={() => next("ground")} >From ground-floor</button>
                    <button className={classnames({ [styles.active]: nextAccess === "stairs" })} onClick={() => next("stairs")} >Using stairs</button>
                    <button className={classnames({ [styles.active]: nextAccess === "elevator" })} onClick={() => next("elevator")} >Using elevator</button>
                </div>
            </div>
            <WhatFloor visible={floorsVisible[1]} value={floorTo} onChange={o => {
                setFloorTo(o)
                setTimeout(() => {
                    nextPage({
                        old_access: { type: access, info: access === "stairs" ? ({ floor: floorFrom }) : ({}) },
                        new_access: { type: nextAccess, info: { floor: o } }
                    })
                }, 500)
            }} />
        </>
    )
}

const MoblityPage = inject(stores => ({ ui: stores.quote_store }))(
    observer(({ ui }) => (
        <div className={styles.wrapper}>
            <Form ui={ui} />
        </div>
    ))
)

export default MoblityPage