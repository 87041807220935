import React, { useState } from "react"
import styles from "./style.module.css"
import classnames from "classnames"
import { observer, inject } from "mobx-react"
import Contacts from "../../../Contacts"


const FailurePage = inject(stores => ({ ui: stores.quote_store }))(
    observer(() => (
        <div className={styles.wrapper}>
            <h2>Whoops! something went wrong, sadly.</h2>
            <p>Get in touch and we'll set you up with the right moving deal.</p>
            <Contacts />

        </div>
    ))
)

export default FailurePage