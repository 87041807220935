import React, { useState } from "react"
import styles from "./style.module.css"
import classnames from "classnames"
import { observer, inject } from "mobx-react"

const StartForm = ({ ui }) => {
    const [packed, _setPacked] = useState(null)
    const setPacked = (flag) => {
        _setPacked(flag)
        setTimeout(() => {
            if (flag) {
                ui.next("boxes", { packed: flag })
            } else {
                ui.next("packing", { packed: flag })
            }
        }, 200)
    }
    return (
        <>
            <h2>Will you take care of packing your stuff in boxes?</h2>
            <div className="d-flex justify-content-center">
                <button className={classnames({ [styles.active]: packed == true })} onClick={() => setPacked(true)} >Yes</button>
                <button className={classnames({ [styles.active]: packed == false })} onClick={() => setPacked(false)}>No, I need help</button>
            </div>
        </>
    )
}

const HasPackagesPage = inject(stores => ({ ui: stores.quote_store }))(
    observer(({ ui }) => (
        <div className={styles.wrapper}>
            <StartForm ui={ui} />
        </div>
    ))
)

export default HasPackagesPage