import React, { useState } from "react"
import styles from "./style.module.css"
import classnames from "classnames"
import { observer, inject } from "mobx-react"
import ReactTooltip from "react-tooltip"

const Form = ({ ui }) => {
    const [small, setSmall] = useState("")
    const [medium, setMedium] = useState("")
    const [large, setLarge] = useState("")
    const canProceed = [small, medium, large].map(x => parseInt(x, 10)).some(x => x > 0)
    return (
        <div>
            <div className="d-flex">
                <label className="flex-fill">Bags</label>
                <input id="small-box" value={small} onChange={e => setSmall(Number.isInteger(parseInt(e.target.value, 10)) ? Math.abs(e.target.value) : "")} name="small-box" type="number" min="0" step="1" placeholder="Type number..."></input>
                <p data-tip="Things that can be carried in a bag. Like a baby cactus or your puppet collection (we dont't judge).">?</p>
            </div>
            <div className="d-flex">
                <label className="flex-fill">Boxes</label>
                <input id="medium-box" value={medium} onChange={e => setMedium(Number.isInteger(parseInt(e.target.value, 10)) ? Math.abs(e.target.value) : "")} name="small-box" type="number" min="0" step="1" placeholder="Type number..."></input>
                <p data-tip="Any cardboard or plastic box you think a person could carry.">?</p>
            </div>
            <div className="d-flex">
                <label className="flex-fill">Bulky items</label>
                <input id="large-box" value={large} onChange={e => setLarge(Number.isInteger(parseInt(e.target.value, 10)) ? Math.abs(e.target.value) : "")} name="small-box" type="number" min="0" step="1" placeholder="Type number..."></input>
                <p data-tip="Anything a person cannot carry alone. Think fridges, big-bad 4K TVs, or a grand piano.">?</p>
            </div>
            <button
                disabled={!canProceed}
                className={classnames("w-100 bg-primary text-white text-fatty", { "invisible": !canProceed })}
                onClick={() => {
                    ui.next("quote", {
                        boxes: {
                            packed: true,
                            small: small,
                            medium: medium,
                            large: large
                        }
                    })
                }}
            >Next</button>
        </div>
    )
}

const BoxesPage = inject(stores => ({ ui: stores.quote_store }))(
    observer(({ ui }) => (
        <div className={styles.wrapper}>
            <h2>How many things are you taking with you?</h2>
            <h3>Give your best estimate!</h3>
            <Form ui={ui} />
            <ReactTooltip place="left" />
        </div>
    ))
)

export default BoxesPage