import React, { useState } from "react"
import styles from "./style.module.css"
import classnames from "classnames"
import { observer, inject } from "mobx-react"

const Form = ({ ui }) => {
    const [access, setAccess] = useState("")
    const [nextAccess, setNextAccess] = useState("")
    const [nextVisible, setNextVisible] = useState(false)
    const current = (x) => {
        setAccess(x)
        setTimeout(() => {
            setNextVisible(true)
        }, 100)
    }
    const next = (x) => {
        setNextAccess(x)
        setTimeout(() => {
            ui.next("packages", {
                old_access: access,
                new_access: x
            })
        }, 200)
    }
    return (
        <>
            <h2>How can we access your current home?</h2>
            <div className="d-flex flex-wrap justify-content-center">
                <button className={classnames({ [styles.active]: access === "ground" })} onClick={() => current("ground")} >From ground-floor</button>
                <button className={classnames({ [styles.active]: access === "stairs" })} onClick={() => current("stairs")} >Using stairs</button>
                <button className={classnames({ [styles.active]: access === "elevator" })} onClick={() => current("elevator")} >Using elevator</button>
            </div>
            <div className={classnames({ "invisible": !nextVisible })}>
                <h2>And your new home?</h2>
                <div className="d-flex flex-wrap justify-content-center">
                    <button className={classnames({ [styles.active]: nextAccess === "ground" })} onClick={() => next("ground")} >From ground-floor</button>
                    <button className={classnames({ [styles.active]: nextAccess === "stairs" })} onClick={() => next("stairs")} >Using stairs</button>
                    <button className={classnames({ [styles.active]: nextAccess === "elevator" })} onClick={() => next("elevator")} >Using elevator</button>
                </div>
            </div>
        </>
    )
}

const MoblityPage = inject(stores => ({ ui: stores.quote_store }))(
    observer(({ ui }) => (
        <div className={styles.wrapper}>
            <Form ui={ui} />
        </div>
    ))
)

export default MoblityPage