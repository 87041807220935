import React, { useState } from "react"
import PropTypes from "prop-types"
import styles from "./style.module.css"
import classnames from "classnames"
import * as locationSVC from "../../services/postcodes"

const AddressLookup = ({ onSuccess }) => {
    const [postcode, setPostcode] = useState("")
    const [isWrongPostcode, setWrongPostcode] = useState(false)
    return (
        <div className={classnames(styles.wrapper, { [styles.error]: isWrongPostcode })}>
            <span className="w-100">{isWrongPostcode ? "This postcode is not on the map. Please make sure you typed it correctly." : ""}</span>
            <div className="d-flex">
                <button disabled={true}>UK</button>
                <input value={postcode} onChange={e => {
                    setWrongPostcode(false) //restart
                    setPostcode(e.target.value.toUpperCase())
                }} className="flex-fill" placeholder="Post Code"></input>
            </div>
            <button
                disabled={postcode.length < 2}
                className={classnames("w-100 bg-primary text-white text-fatty", { "invisible": postcode.length < 2 })}
                onClick={() => {
                    locationSVC.getUKPostcodeInfo(postcode)
                        .then(data => {
                            onSuccess({
                                postcode: postcode,
                                long: data.longitude,
                                lat: data.latitude,
                                withinM25: locationSVC.withinM25(postcode)
                            })
                        })
                        .catch(err => {
                            console.error(err)
                            setWrongPostcode(true)
                        })
                }}
            >Next</button>
        </div>
    )
}

AddressLookup.propTypes = {
    onSuccess: PropTypes.func.isRequired
}

export default AddressLookup