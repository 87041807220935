import React from "react"
import styles from "./style.module.css"
import { observer, inject } from "mobx-react"
import AddressLookup from "../../../AddressLookup"



const MoveToPage = inject(stores => ({ ui: stores.quote_store }))(
    observer(({ ui }) => (
        <div className={styles.wrapper}>
            <h2>And where are you moving to?</h2>
            <AddressLookup onSuccess={data => {
                ui.next("property", {
                    to: data
                })
            }} />
        </div>
    ))
)

export default MoveToPage