import { observable, action, computed, toJS } from "mobx";
import { calculateTrip } from "../services/trip";
import { getFuelPrice } from "../services/fuel";

//how many litres of fuel a van consumes per kilometre
const KM_PER_LITRE = 7.25

const MIN_SERVICE_HOURS = 2

//values in cubic meters
//a bag or a small box
const SMALL_BOX_VOLUME = 0.3 * 0.3 * 0.15
//a typical moving box
const MED_BOX_VOLUME = .45 * .45 * .75
//furniture or bulky stuff
const LARGE_BOX_VOLUME = .9 * 1.8 * .6

//van thresholds
const MAX_SMALL_VAN_VOLUME = 10 * SMALL_BOX_VOLUME + 5 * MED_BOX_VOLUME
const MAX_MED_VAN_VOLUME = 20 * SMALL_BOX_VOLUME + 10 * MED_BOX_VOLUME + 2 * LARGE_BOX_VOLUME

//trips per person
const TRIP_DURATION_MINUTES = 15
const TRIP_STAIRS_DELAY_MINUTES = 10
const TRIP_ELEVATOR_DELAY_MINUTES = 5
const SMALL_BOXES_PER_TRIP = 8
const MED_BOXES_PER_TRIP = 2
const LARGE_BOXES_PER_TRIP = 0.5 //requires 2 people

//fields required to issue quote
const REQUIRED_FIELDS = [
    "from",
    "to",
    "old_access",
    "new_access",
    "boxes"
]

class QuoteStore {

    @observable answers = []

    @observable stack = ["welcome"]

    @observable editable = true

    //answer overrides
    @observable peopleOverride = null
    @observable hoursOverride = null
    @observable vanOverride = null

    @observable drivingHours = null
    @observable tripKM = null
    @observable fuelPricePerKM = null
    @observable fuelError = false

    constructor(data) {
        if (data) {
            this.editable = false
            this.stack.push("quote")
            this.answers.push(data)
        }
    }

    @action.bound
    setFuelPricePerKM(price) {
        this.fuelPricePerKM = price
    }

    @action.bound
    loadFuelPrice() {
        this.fuelError = false
        return getFuelPrice()
            .then(action(pricePerLitre => this.fuelPricePerKM = pricePerLitre / KM_PER_LITRE))
            .catch(err => this.fuelError = err)
    }

    @action.bound
    calculateTripDistance() {
        this.fuelError = false
        const answers = this.allAnswers
        const home_base = { lat: parseFloat(process.env.BASE_LATITUDE), long: parseFloat(process.env.BASE_LONGITUDE) }
        const section_1 = [
            home_base,
            { lat: answers.from.lat, long: answers.from.long }
        ]
        const section_2 = [
            { lat: answers.from.lat, long: answers.from.long },
            { lat: answers.to.lat, long: answers.to.long },
        ]
        const section_3 = [
            { lat: answers.to.lat, long: answers.to.long },
            home_base
        ]
        return calculateTrip([section_1, section_2, section_3])
            .then(action((trip) => {
                this.tripKM = Math.ceil(trip.distance / 1000) //metres -> km
                this.drivingHours = Math.ceil(trip.duration / (60 * 60)) //seconds -> hour
                console.log(this.tripKM, this.drivingHours, trip)
            }))
            .catch(err => this.fuelError = err)
    }

    @action.bound
    setEditable(flag) {
        this.editable = flag
    }

    @action.bound
    setPeopleOverride(x) {
        this.peopleOverride = x
    }

    @action.bound
    setHoursOverride(x) {
        this.hoursOverride = x
    }

    @action.bound
    setVanOverride(x) {
        this.vanOverride = x
    }


    @action.bound
    restart() {
        this.stack = ["welcome"]
        this.answers = []
    }

    @action.bound
    back() {
        if (this.canGoBack) {
            this.stack.pop()
            this.answers.pop()
            this.peopleOverride = null
            this.hoursOverride = null
            this.vanOverride = null
        }
    }

    @action.bound
    next(page, currentAnswer = {}) {
        if (page === "welcome") return //we don't want to stack the welcome page
        if (page === this.currentPage) return //we don't want to have two same pages in a row
        this.stack.push(page)
        this.answers.push(currentAnswer)
    }

    @action.bound
    fail() {
        this.next("failure")
    }

    @computed
    get currentPage() {
        return this.stack[this.stack.length - 1]
    }

    @computed
    get failed() {
        return this.currentPage === "failure"
    }

    @computed
    get canGoBack() {
        return this.stack.length > 1
    }


    @computed
    get allAnswers() {
        let merge = {}
        this.answers.forEach(a => merge = { ...merge, ...a })
        return merge
    }

    @computed
    get boxVolume() {
        const answers = this.allAnswers
        if (answers.boxes) {
            return (
                (answers.boxes.small || 0) * SMALL_BOX_VOLUME +
                (answers.boxes.medium || 0) * MED_BOX_VOLUME +
                (answers.boxes.large || 0) * LARGE_BOX_VOLUME
            )
        } else {
            return 0
        }
    }


    @computed
    get trips() {
        //If there are two people going at the same time, we count 1 trip. 
        const answers = this.allAnswers
        if (answers.boxes) {
            return (
                (answers.boxes.small || 0) / SMALL_BOXES_PER_TRIP +
                (answers.boxes.medium || 0) / MED_BOXES_PER_TRIP +
                (answers.boxes.large || 0) / LARGE_BOXES_PER_TRIP
            )
        } else {
            return 1
        }
    }

    @computed
    get suggestedVan() {
        const answers = this.allAnswers
        const boxVolume = this.boxVolume
        //based on volume
        let van = "small"
        if (boxVolume > MAX_SMALL_VAN_VOLUME) {
            van = "longwheel"
        } else if (boxVolume > MAX_MED_VAN_VOLUME) {
            van = "luton"
        }
        //overrides
        if (answers.boxes && answers.boxes.large > 2) {
            van = "luton"
        }
        return van
    }

    @computed
    get minPeople() {
        const answers = this.allAnswers
        const hasBulkItems = answers.boxes && answers.boxes.large > 0
        return hasBulkItems ? 2 : 1
    }


    @computed
    get suggestedPeople() {
        const answers = this.allAnswers
        const hardtoReach = answers.old_access === "stairs" || answers.new_access === "stairs"
        const hasBulkItems = answers.boxes.large > 0
        return hardtoReach || hasBulkItems ? 2 : 1
    }

    @computed
    get peoplePossibleOverrides() {
        return [1, 2, 3].filter(x => x >= this.minPeople)
    }


    @computed
    get needsPacking() {
        return false
    }


    @computed
    get suggestedHours() {
        const answers = this.allAnswers
        const startTripDuration = TRIP_DURATION_MINUTES +
            (answers.old_access === "stairs" ? 1 : 0) * TRIP_STAIRS_DELAY_MINUTES +
            (answers.old_access === "elevator" ? 1 : 0) * TRIP_ELEVATOR_DELAY_MINUTES
        const endTripDuration = TRIP_DURATION_MINUTES +
            (answers.new_access === "stairs" ? 1 : 0) * TRIP_STAIRS_DELAY_MINUTES +
            (answers.new_access === "elevator" ? 1 : 0) * TRIP_ELEVATOR_DELAY_MINUTES

        const people = Math.max(this.minPeople, this.peopleOverride || this.suggestedPeople)
        //more people -> less trips
        const trips = this.trips / people
        return Math.ceil(
            Math.max(
                this.minHours,
                //minutes -> hours
                this.drivingHours + (trips * (startTripDuration + endTripDuration)) / 60
            )
        )
    }

    @computed
    get hoursPossibleOverrides() {
        return Array(24).fill().map((_,i) => i + 1).filter(x => x >= this.minHours)
    }

    @computed
    get hourPrice() {
        const people = this.peopleOverride || this.suggestedPeople
        const van = this.vanOverride || this.suggestedVan

        let res = 0
        if (van === "small" || van === "longwheel") {
            switch (people) {
                case 1:
                    res = 35
                    break;
                case 2:
                    res = 45
                    break;
                default:
                    res = 70
            }
        } else {
            switch (people) {
                case 1:
                    res = 45
                    break;
                case 2:
                    res = 55
                    break;
                default:
                    res = 70
            }
        }
        return res
    }

    @computed
    get tripPriceWaived() {
        //free of charge if both pickup and destination are within m25
        const allAnswers = this.allAnswers
        const { from, to } = allAnswers
        return from.withinM25 && to.withinM25
    }

    @computed
    get tripPrice() {
        return Math.ceil(this.tripKM * this.fuelPricePerKM)
    }


    @computed
    get manAndVanPrice() {
        const hours = this.hoursOverride || this.suggestedHours
        return this.hourPrice * hours
    }

    @computed
    get minHours() {
        return Math.ceil(Math.max(MIN_SERVICE_HOURS, this.drivingHours))
    }


    @computed
    get progress() {
        const answers = this.allAnswers
        const requiredFields = REQUIRED_FIELDS
        const percent = (requiredFields.map(field => Boolean(answers[field])).filter(x => x).length / requiredFields.length) * 100
        return Math.max(this.failed ? 100 : percent, 1)
    }


    @computed
    get url() {
        const ser = JSON.stringify({ ...toJS({ ...this.allAnswers }), version: 1 })
        return `/quote?id=${btoa(ser)}`
    }

    static staticQuote(serialized) {
        try {
            const j = JSON.parse(atob(serialized))
            const requiredFields = REQUIRED_FIELDS
            if (requiredFields.map(field => Boolean(j[field])).filter(x => x).length !== requiredFields.length) {
                throw new Error("Invalid Quote Id")
            }
            return new QuoteStore(j)
        } catch (e) {
            console.error(e)
            return new QuoteStore()
        }
    }


}

export default QuoteStore