import React, { useState } from "react"
import styles from "./style.module.css"
import classnames from "classnames"
import { observer, inject } from "mobx-react"
import ReactDropdown from "react-dropdown"

const Form = ({ ui }) => {
    const [van, setVan] = useState("")
    const [men, setMen] = useState("")
    const [hours, setHours] = useState("")
    const fullSelection = [van, men, hours].every(Boolean)
    const partialSelection = [van, men, hours].some(Boolean)
    return (
        <>

            <div>
                <h3>Type of van</h3>
                <ReactDropdown
                    className={styles.dropdown}
                    id="selected-van"
                    options={[
                        { value: "small", label: "Small Van" },
                        { value: "longwheel", label: "Medium Van" },
                        { value: "luton", label: "Large Van" },
                    ]}
                    value={van}
                    onChange={o => setVan(o.value)}
                />
                <h3>Number of helpers</h3>
                <ReactDropdown
                    className={styles.dropdown}
                    id="selected-men"
                    options={[1, 2, 3].map(o => ({ value: o }))}
                    value={`${men}`}
                    onChange={o => setMen(o.value)}
                />
                <h3>Booking hours</h3>
                <ReactDropdown
                    className={styles.dropdown}
                    id="selected-hours"
                    options={[].concat([2, 3, 4, 5, 6, 7, 8, 9, 10, 11], "12+").map(o => ({ value: o, label: `${o}` }))}
                    value={`${hours}`}
                    onChange={o => setHours(o.value)}
                />
                {/* <div className="d-flex align-items-center">
                    <label className="flex-fill" htmlFor="selected-hours">Booking hours</label>
                </div> */}
                <button
                    disabled={partialSelection}
                    className={classnames("w-100 bg-primary text-white text-fatty", { "d-none": partialSelection })}
                    onClick={() => {
                        ui.next("quote", {
                            service: {
                                van: "N/A",
                                men: "N/A",
                                hours: "N/A",
                            }
                        })
                    }}
                >
                    Help me choose
                </button>
                <button
                    disabled={!fullSelection}
                    className={classnames("w-100 bg-primary text-white text-fatty", { "invisible": !fullSelection })}
                    onClick={() => {
                        ui.next("quote", {
                            service: {
                                van,
                                men,
                                hours
                            }
                        })
                    }}
                >
                    Next
            </button>
            </div>
        </>
    )
}

const ServicePage = inject(stores => ({ ui: stores.quote_store }))(
    observer(({ ui }) => (
        <div className={styles.wrapper}>
            <h2>Which service did you have in mind?</h2>
            <p>We can help you choose based on your needs, or you can tell us exactly what you want</p>
            <Form ui={ui} />
        </div>
    ))
)

export default ServicePage