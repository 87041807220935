import { api } from "../utils/url"


export const requestQuote = (data) => {
    return fetch(api(`/quote`), {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "accepts": "application/json"
        }
    })
        .then(resp => resp.json())
        .then(data => {
            if (data.success) return data.result
            else throw new Error(data.message)
        })
}

export const quoteEmailURL = (data) => {
    const { info } = data
    const body = [].concat([
        `Just send this email and we'll get right back to you with a quote.`,
        ``,
        ``,
        ``,
        `^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^`,
        `Booking Date: ${info.date}`,
        `__________________________________`,
        `Client`,
        `----------------------------------`,
        `Name: ${info.name}`,
        // `Email: ${info.email}`,
        `Phone: +${info.phone}`,
        `__________________________________`,
        `Trip`,
        `----------------------------------`,
        `From: ${data.trip.from}`,
        `To: ${data.trip.to}`,
        `__________________________________`,
        `Loading Info`,
        `----------------------------------`,
        `Pick Up Property Type: ${data.property.type}`,
        `Pick Up Accessibility: ${data.accessibility.from.type}${data.accessibility.from.info.floor ? ` - floor: ${data.accessibility.from.info.floor}` : ""}`,
        `Drop Accessibility: ${data.accessibility.to.type}${data.accessibility.to.info.floor ? ` - floor: ${data.accessibility.to.info.floor}` : ""}`,
        `___________________________________`,
        `Packing Info`,
        `-----------------------------------`,
        `Requires Packing: ${data.service.packing ? "Yes" : "No"}`,
        ...(data.service.packing ?
            [
                `Note`,
                `^^^^`,
                data.items.note
            ] :
            [
                `Small Boxes #: ${data.items.small || "N/A"}`,
                `Medium Boxes #: ${data.items.medium || "N/A"}`,
                `Large items #: ${data.items.large || "N/A"}`,
            ]),
        `___________________________________`,
        `Service Info`,
        `-----------------------------------`,
        `Insure client goods: ${data.service.warranty ? "Yes": "No"}`,
        ...([data.service.men, data.service.van, data.service.hours].every(x => x === "N/A") ?
            [`Client neeeds help choosing Men/Van/Hours`] :
            [
                `Men: ${data.service.men}`,
                `Van: ${data.service.van}`,
                `Hours: ${data.service.hours}`
            ]),
        ``,
        `^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^`,
        ``

    ]).join("\n")
    return `mailto:contact@theremovalman.co.uk?subject=The Removal Man - New Quote&body=${encodeURIComponent(body)}`
}