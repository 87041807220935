import React from "react"
import styles from "./style.module.css"
import { observer, inject } from "mobx-react"
import AddressLookup from "../../../AddressLookup"

const WelcomePage = inject(stores => ({ ui: stores.quote_store }))(
    observer(({ ui }) => (
        <div className={styles.wrapper}>
            <h2>Where are you moving from?</h2>

            <AddressLookup onSuccess={data => {
                ui.next("move-to", {
                    from: data
                })
            }} />

        </div>
    ))
)

export default WelcomePage