import React, { useState, useEffect } from "react"
import styles from "./style.module.css"
import classnames from "classnames"
import { observer, inject } from "mobx-react"
import ReactDropdown from "react-dropdown"
import 'react-dropdown/style.css';
import Telephone from "../../../Telephone"
import { Link } from "gatsby"
import { observable, runInAction } from "mobx"
import Loader from "../../../Loader"

const Share = ({ ui, tracker }) => {
    const [shareable, setShareable] = useState(false)
    useEffect(() => {
        setShareable(Boolean((navigator || {}).share))
    }, [])
    if (!shareable) return null
    return (
        <div className={styles.share}>
            <button aria-label="Share"
                onClick={() => {
                    navigator.share({
                        title: 'Your moving quote',
                        text: 'The Removal Man quote',
                        url: `${process.env.SITE_URL}${ui.url}`,
                    })
                        .then(() => {
                            tracker.trackWithGA({
                                eventCategory: "Quote",
                                eventAction: "share"
                            })
                        })
                        .catch((error) => console.error('Error sharing', error));
                }}
            ><i className="icon-share" /></button>
        </div>
    )
}

const Form = observer(({ ui, tracker }) => {

    return (
        <form className="position-relative paper-low" onSubmit={e => e.preventDefault()}>
            <div className="d-flex align-items-center">
                <label className="flex-fill" htmlFor="selected-van">Van</label>
                <ReactDropdown
                    className={styles.dropdown}
                    id="selected-van"
                    options={[
                        { value: "small", label: "Small Van" },
                        { value: "longwheel", label: "Long Wheelbase" },
                        { value: "luton", label: "Luton" },
                    ]}
                    value={ui.vanOverride || ui.suggestedVan}
                    onChange={o => ui.setVanOverride(o.value)}
                />
            </div>
            <div className="d-flex align-items-center">
                <label className="flex-fill" htmlFor="selected-men">Men</label>
                <ReactDropdown
                    className={styles.dropdown}
                    id="selected-men"
                    options={ui.peoplePossibleOverrides.map(o => ({ value: o }))}
                    value={`${ui.peopleOverride || ui.suggestedPeople}`}
                    onChange={o => ui.setPeopleOverride(o.value)}
                />
            </div>
            <div className="d-flex align-items-center">
                <label className="flex-fill" htmlFor="selected-hours">Hours</label>
                <ReactDropdown
                    className={styles.dropdown}
                    id="selected-hours"
                    options={ui.hoursPossibleOverrides.map(o => ({ value: o }))}
                    value={`${ui.hoursOverride || ui.suggestedHours}`}
                    onChange={o => ui.setHoursOverride(o.value)}
                />
            </div>
            <hr />
            <Breakdown />
            {/* <output>
                <strong>Total </strong> £{ui.manAndVanPrice + ui.tripPrice}
            </output> */}
            <Navigation />
            <footer>
                {ui.tripPriceWaived ? (
                    <p>
                        <strong>Note: </strong>
                        overtime paid by the hour, at a rate of £{ui.hourPrice}/h; <br />
                        <small>Full T&Cs <Link to="/tos">here</Link></small>
                    </p>
                ) : (
                        <p>
                            <strong>Note: </strong>
                            overtime paid by the hour, at a rate of £{ui.hourPrice}/h; <br />
                            fuel prices based on daily estimates. <br />
                            <small>Full T&Cs <Link to="/tos">here</Link></small>
                        </p>
                    )}
                <Share ui={ui} tracker={tracker} />
            </footer>
        </form>
    )
})


const Breakdown = inject(stores => ({
    ui: stores.quote_store
}))(
    observer(({ ui }) => (
        <details>
            <summary>
                <strong>Total </strong> <span>£{ui.manAndVanPrice + ui.tripPrice}</span>
            </summary>
            <table>
                <tbody>

                    <tr>
                        <td>
                            Trip
                    </td>
                        <td>
                            {ui.allAnswers.from.postcode} <span style={{ fontSize: "1rem", fontWeight: "bold", margin: "0 0.5rem" }}>&#8594;</span>{ui.allAnswers.to.postcode}
                        </td>
                    </tr>
                    {ui.tripPriceWaived ? (
                        <tr>
                            <td>
                                Estimated trip cost
                            </td>
                            <td>
                                FREE
                            </td>
                        </tr>
                    ) : (
                            <>
                                <tr>
                                    <td>
                                        Round-trip distance
                                    </td>
                                    <td>
                                        {ui.tripKM} km
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Estimated driving time
                                    </td>
                                    <td>
                                        {ui.drivingHours} hours
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Estimated fuel cost
                                </td>
                                    <td>
                                        £{ui.tripPrice}
                                    </td>
                                </tr>
                            </>
                        )}

                    <tr>
                        <td>
                            Man & Van service cost
                    </td>
                        <td>
                            £{ui.manAndVanPrice}
                        </td>
                    </tr>
                </tbody>
            </table>
            {/* <ul>
                <li><strong>Trip </strong> {ui.allAnswers.from.postcode} <span style={{ fontSize: "1rem", fontWeight: "bold", margin: "0 0.5rem" }}>&#8594;</span>{ui.allAnswers.to.postcode}</li>
                <li><strong>Total distance</strong> {ui.tripKM} km</li>
                <li><strong>Estimated trip cost</strong> £{ui.tripPrice}</li>
                <li><strong>Man & Van service cost</strong> £{ui.manAndVanPrice}</li>
            </ul> */}
        </details>
    ))
)

const Navigation = inject(stores => ({
    header: stores.header_store,
    chat: stores.chat_store,
    tracker: stores.tracker_controller,
    ui: stores.quote_store
}))(
    observer(({ ui, header, chat, tracker }) => (
        <div className={styles.nav}>
            <p>book</p>
            <div className={classnames("d-flex align-items-center")}>
                <button className="btn-cta"
                    onClick={() => {
                        chat.setQuestion(`Hi, I am interested in booking your services based on this quote: ${process.env.SITE_URL}${ui.url}`)
                        chat.setVisible(true)
                        chat.setExpanded(true)
                        tracker.trackWithGA({
                            eventCategory: "Quote",
                            eventAction: "bookByChat",
                            eventValue: 1
                        })
                    }}
                >by chat</button>
                <Telephone className="btn-cta"
                    onDesktopClick={() => {
                        header.setCallIntentVisible(true)
                        tracker.trackWithGA({
                            eventCategory: "Quote",
                            eventAction: "bookByTelephone",
                            eventValue: 1
                        })
                    }}
                    onMobileClick={() => {
                        tracker.trackWithGA({
                            eventCategory: "Quote",
                            eventAction: "bookByTelephone",
                            eventValue: 1
                        })
                    }}
                >
                    by phone
                </Telephone>
            </div>
        </div>
    ))
)

const Loading = ({ what }) => (
    <div className={"d-flex flex-col align-items-center"}>
        <p>
            {what}
        </p>
        <Loader color="#2db7f5" />
    </div>
)

@inject(stores => ({
    ui: stores.quote_store,
    tracker: stores.tracker_controller
}))
@observer
class ResultPage extends React.Component {

    @observable distanceLoaded = false
    @observable fuelPriceLoaded = false

    @observable loadingMsg = ""


    async loadExternalData() {
        const { ui } = this.props
        if (!ui.tripPriceWaived) {
            runInAction(() => this.loadingMsg = "Calculating driving distance...")
            try {
                await ui.calculateTripDistance()
                runInAction(() => this.distanceLoaded = true)
            } catch (err) {
                console.error(err)
                ui.fail()
            }

            runInAction(() => this.loadingMsg = "Loading latest fuel prices...")
            try {
                await ui.loadFuelPrice()
                runInAction(() => this.fuelPriceLoaded = true)
            } catch (err) {
                console.error(err)
                ui.fail()
            }
        }
    }

    componentDidMount() {
        this.loadExternalData()
    }

    render() {
        const { ui, tracker } = this.props
        if (!ui.tripPriceWaived && (!this.distanceLoaded || !this.fuelPriceLoaded)) {
            return <Loading what={this.loadingMsg} />
        }
        return (
            <div className={classnames(styles.wrapper)}>
                <div>
                    <h2>Your quote</h2>
                    <p>Based on what you told us, we suggest you choose the following service.</p>
                </div>
                <Form ui={ui} tracker={tracker} />
            </div>
        )
    }
}


export default ResultPage