import React from "react"
import classnames from "classnames"
import styles from "./style.module.css"

const Checkbox = ({ checked, onChange, name, children, className, ...otherProps }) => (
    <label htmlFor={name} className={classnames({ [styles.checked]: checked }, className, styles.wrapper)}>
        <input name={name} type="checkbox" checked={checked} onChange={onChange} {...otherProps} />
        <div className="d-flex align-items-center">
            <span className={classnames(styles.check, { "mr-2": children })} />
            {children}
        </div>
    </label>
)

export default Checkbox