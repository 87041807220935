import React from "react"
import classnames from "classnames"
import styles from "./style.module.css"
import { observer, inject } from "mobx-react"
import StartPage from "./pages/Start"
import MoveToPage from "./pages/MoveTo"
import { Line } from "rc-progress"
import MoblityPage from "./pages/Mobility"
import HasPackagesPage from "./pages/Packages"
import FailurePage from "./pages/Failed"

import BoxesPage from "./pages/Boxes"
import ResultPage from "./pages/Result"
import PropertyPage from "./pages/Property"
import PackingPage from "./pages/Packing"
import ServicePage from "./pages/Service"



@inject(stores => ({
    ui: stores.quote_store
}))
@observer
class PageRouter extends React.Component {


    componentDidUpdate(){
        const nav = document.getElementById("quote-nav")
        if (nav){
            nav.scrollIntoView()
        }
    }

    render() {
        const { ui } = this.props
        console.log(ui.currentPage)
        switch (ui.currentPage) {
            case "move-to": return <MoveToPage />
            case "property": return <PropertyPage />
            case "mobility": return <MoblityPage />
            case "packages": return <HasPackagesPage />
            case "packing": return <PackingPage />
            case "boxes": return <BoxesPage />
            case "service": return <ServicePage />
            case "failure": return <FailurePage />
            case "quote": return <ResultPage />
            default: return <StartPage />
        }
    }
}

const Navigation = inject(stores => ({ ui: stores.quote_store }))(
    observer(({ ui }) => (
        <div id="quote-nav" className={classnames("d-flex justify-content-between align-items-center", styles.nav)}>
            <button onClick={ui.back} disabled={!ui.canGoBack} className={classnames("btn-link", { "invisible": !ui.canGoBack })}><i className="icon-chevron_left" /></button>
            {/* <h1>{ui.currentTitle}</h1> */}
            <div style={{ maxWidth: "280px", width: "100%" }} className="w-100">
                <Line percent={ui.progress}
                    strokeLinecap="round"
                    strokeWidth="5"
                    trailWidth="5"
                    trailColor="#f9f9f9"
                    strokeColor={ui.failed ? "#fad201" :
                        ui.progress < 50 ? "#46da93" : "#2db7f5"
                    }
                />
            </div >
            <div style={{ width: "48px" }} />
        </div>
    ))
)

const FormQuote = () => (
    <div className={classnames(styles.wrapper, "top-offset d-flex align-items-center")}>
        <div className={classnames(styles.widget)}>
            <Navigation />
            {/* <hr /> */}
            <div className={styles.questionaire}>
                <PageRouter />
            </div>
        </div>
    </div>
)

export default FormQuote