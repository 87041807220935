import { observable, action, computed } from "mobx";

//fields required to issue quote
const REQUIRED_FIELDS = [
    "from",
    "to",
    "old_access",
    "new_access",
    "boxes",
    "packed",
    "property",
    "service"
]

class QuoteStore2 {

    @observable answers = []

    @observable stack = ["welcome"]

    // //answer overrides
    // @observable peopleOverride = null
    // @observable hoursOverride = null
    // @observable vanOverride = null


    // @action.bound
    // setPeopleOverride(x) {
    //     this.peopleOverride = x
    // }

    // @action.bound
    // setHoursOverride(x) {
    //     this.hoursOverride = x
    // }

    // @action.bound
    // setVanOverride(x) {
    //     this.vanOverride = x
    // }


    @action.bound
    restart() {
        this.stack = ["welcome"]
        this.answers = []
    }

    @action.bound
    back() {
        if (this.canGoBack) {
            this.stack.pop()
            this.answers.pop()
            this.peopleOverride = null
            this.hoursOverride = null
            this.vanOverride = null
        }
    }

    @action.bound
    next(page, currentAnswer = {}) {
        if (page === "welcome") return //we don't want to stack the welcome page
        if (page === this.currentPage) return //we don't want to have two same pages in a row
        this.stack.push(page)
        this.answers.push(currentAnswer)
    }

    @action.bound
    fail() {
        this.next("failure")
    }

    @computed
    get currentPage() {
        return this.stack[this.stack.length - 1]
    }

    @computed
    get failed() {
        return this.currentPage === "failure"
    }

    @computed
    get canGoBack() {
        return this.stack.length > 1
    }


    @computed
    get allAnswers() {
        let merge = {}
        this.answers.forEach(a => merge = { ...merge, ...a })
        return merge
    }


    @computed
    get progress() {
        const answers = this.allAnswers
        const requiredFields = REQUIRED_FIELDS
        const percent = (requiredFields.map(field => Boolean(answers[field])).filter(x => x).length / requiredFields.length) * 100
        return Math.max(this.failed ? 100 : percent, 1)
    }



}

export default QuoteStore2