import React, { useState } from "react"
import styles from "./style.module.css"
import classnames from "classnames"
import { observer, inject } from "mobx-react"
import Telephone from "../../../Telephone"
import PhoneInput from "react-phone-input-2"

import ReactDatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import 'react-phone-input-2/lib/style.css'
import * as quoteSvc from "../../../../services/quote"
import { runInAction } from "mobx"
import Checkbox from "../../../Checkbox"

const Form = ({ ui, isMobile, tracker }) => {
    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [number, setNumber] = useState("")
    const [date, setDate] = useState("")
    const [insurance, setInsurance] = useState(false)

    //desktop only
    const [loading, setLoading] = useState(false)
    const [result, setResult] = useState("pending")

    const canSubmit = [name, number.length >= 9, date].every(Boolean)
    return (
        <form className="position-relative paper-low" onSubmit={e => {
            e.preventDefault()
            const answers = ui.allAnswers
            const emailData = {
                info: {
                    name,
                    phone: number,
                    email: email,
                    date: date.toDateString()
                },
                trip: {
                    from: answers.from.postcode,
                    to: answers.to.postcode
                },
                property: {
                    type: answers.property,
                },
                accessibility: {
                    from: answers.old_access,
                    to: answers.new_access,
                },
                service: {
                    packing: !answers.packed,
                    men: answers.service.men,
                    van: answers.service.van,
                    hours: answers.service.hours,
                    warranty: insurance
                },
                items: {
                    note: answers.note,
                    small: (answers.boxes || {}).small,
                    medium: (answers.boxes || {}).medium,
                    large: (answers.boxes || {}).large,
                }
            }
            if (isMobile) {
                window.location = quoteSvc.quoteEmailURL(emailData)
            } else {
                setLoading(true)
                quoteSvc.requestQuote(emailData)
                    .then(() => [setResult("success"), setLoading(false)])
                    .catch(() => [setLoading(false), ui.fail()])
            }
        }}>

            <label>Preferred Booking Date</label>
            <ReactDatePicker
                required
                selected={date}
                onChange={x => setDate(x)}
                minDate={new Date()}
            />
            <fieldset>
                <legend>Contact Details</legend>
                <label htmlFor="name">Your name</label>
                <input id="name" type="text" value={name} onChange={e => setName(e.target.value)} />
                {isMobile ? null : (
                    <>
                        <label htmlFor="email">Your email</label>
                        <input required id="email" type="email" pattern="^[a-zA-Z0-9\.%+-_]+@([a-zA-Z0-9\.-_]+\.)?[a-zA-Z0-9\.-_]+\.[A-Za-z]{2,63}$" value={email} onChange={e => setEmail(e.target.value)} />
                    </>
                )}
                <label htmlFor="number">Your phone number</label>
                <div style={{ maxWidth: "220px" }}>
                    <PhoneInput country={"gb"} value={number} onChange={setNumber} />
                </div>
            </fieldset>

            <Checkbox checked={insurance} onChange={() => setInsurance(!insurance)}>Insure your goods up to £25K</Checkbox>
            {/* <a href="/tos" target="_blank"><small style={{ fontSize: "0.7rem" }}>Learn more</small></a> */}

            <button
                type="submit"
                disabled={!canSubmit}
                className={classnames("w-100 bg-primary text-white text-fatty", { "invisible": !canSubmit })}
            >
                Get Quote
            </button>

            {loading ? (
                <div className={styles.info}>
                    <p>Loading...</p>
                </div>
            ) : null}

            {/* {result === "failure" ? (
                <div className={styles.info}>
                    <h3>Whoops!</h3>
                    <p>Loading...</p>
                </div>
            ) : null} */}

            {result === "success" ? (
                <div className={styles.info}>
                    <h3>Thank you for your enquiry</h3>
                    <p>We will send you a quote shortly. <br /> Please keep an eye on your phone or email address!</p>
                </div>
            ) : null}
        </form>
    )
}


const Navigation = inject(stores => ({
    header: stores.header_store,
    chat: stores.chat_store,
    tracker: stores.tracker_controller,
    ui: stores.quote_store
}))(
    observer(({ ui, header, chat, tracker }) => (
        <div className={styles.nav}>
            <p>book</p>
            <div className={classnames("d-flex align-items-center")}>
                <button className="btn-cta"
                    onClick={() => {
                        chat.setQuestion(`Hi, I am interested in booking your services based on this quote: ${process.env.SITE_URL}${ui.url}`)
                        chat.setVisible(true)
                        chat.setExpanded(true)
                        tracker.trackWithGA({
                            eventCategory: "Quote",
                            eventAction: "bookByChat",
                            eventValue: 1
                        })
                    }}
                >by chat</button>
                <Telephone className="btn-cta"
                    onDesktopClick={() => {
                        header.setCallIntentVisible(true)
                        tracker.trackWithGA({
                            eventCategory: "Quote",
                            eventAction: "bookByTelephone",
                            eventValue: 1
                        })
                    }}
                    onMobileClick={() => {
                        tracker.trackWithGA({
                            eventCategory: "Quote",
                            eventAction: "bookByTelephone",
                            eventValue: 1
                        })
                    }}
                >
                    by phone
                </Telephone>
            </div>
        </div>
    ))
)

@inject(stores => ({
    ui: stores.quote_store,
    tracker: stores.tracker_controller,
    context: stores.context_store
}))
@observer
class ResultPage extends React.Component {

    render() {
        const { ui, tracker, context } = this.props
        return (
            <div className={classnames(styles.wrapper)}>
                <div>
                    <h2>Get your quote</h2>
                </div>
                <Form ui={ui} tracker={tracker} isMobile={!context.desktop} />
            </div>
        )
    }
}


export default ResultPage